import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const t = TrelloPowerUp.iframe()
    const cardId = properties.cardId

    const scopeCardId = properties.scopeCardId
    const promise = (scopeCardId) ?
      $tpu.r.tasks.destroy(cardId, scopeCardId, t) :
      $tpu.r.scopes.destroy(cardId, properties.projectId, t)
    this.executeCardDestroy(promise, properties, component, false)
  }
}
